var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { class: _vm.$style.pageTitle }, [_vm._v("ヘルプ")]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("section", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h2", [_vm._v("リンク")])
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c("h4", [
              _vm._v("・"),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://tayori.com/q/aicontact-support/",
                    target: "_BLANK"
                  }
                },
                [
                  _vm._v("【総合】ヘルプページ "),
                  _c("i", { staticClass: "fa fa-external-link" })
                ]
              )
            ]),
            _c("h4", { staticStyle: { "margin-top": "16px" } }, [
              _vm._v("・"),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://tayori.com/q/aicontact-driver-support/",
                    target: "_BLANK"
                  }
                },
                [
                  _vm._v("【ドライバー様向け】ヘルプページ "),
                  _c("i", { staticClass: "fa fa-external-link" })
                ]
              )
            ]),
            _c("h4", { staticStyle: { "margin-top": "16px" } }, [
              _vm._v("・"),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://docs.google.com/presentation/d/e/2PACX-1vRS5ZQ9ez9fLtUgjITrnolXxqsunqDeVCInKB83gdmszCfyBBHyUxzje2gb0tWdFw0WhUG54EraabqH/pub?start=false&loop=false&delayms=3000&slide=id.g25dfbb10d41_0_3225",
                    target: "_BLANK"
                  }
                },
                [
                  _vm._v("ドキュメントマニュアル "),
                  _c("i", { staticClass: "fa fa-external-link" })
                ]
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }